import React from 'react'
// import { Link, Router } from '@reach/router'
// import { HomePage } from './pages/HomePage';
// import { PeoplesPage } from './pages/PeoplePages';
// import { FinancialPage } from './pages/FinancialPage';
// import { SoftwarePage } from './pages/SoftwarePage';
// import { ProcessPage } from './pages/ProcessPage';
// import { DataPage } from './pages/DataPage';
// import MdMenu from 'react-ionicons/lib/MdMenu';
// import IosClose from 'react-ionicons/lib/IosClose';
// import 'react-bulma-components/dist/react-bulma-components.min.css';
// import { Button } from 'react-bulma-components';
// import Navbar from 'react-bulma-components/lib/components/navbar';

// const colors = {
//     Default: '',
//     primary: 'primary',
//     info: 'info',
//     danger: 'danger',
//     warning: 'warning',
//     success: 'success',
//     white: 'white',
//     black: 'black',
//     light: 'light',
//     dark: 'dark',
//     link: 'link',
// };

function App() {
    return (
        <div style={{ display: 'flex', height: '100vh', width: '100vw', justifyContent: 'center', alignItems: 'center' }}>
            <h1>Just having a rest. Back Soon!!</h1>
            {/*<Navbar*/}
            {/*    color={select('Color', colors)}*/}
            {/*    fixed={select('Fixed',*/}
            {/*        { default: undefined, top: 'top', bottom: 'bottom' })}*/}
            {/*    active={boolean('Active', false)}*/}
            {/*    transparent={boolean('Transparent', false)}*/}
            {/*>*/}
            {/*    <Navbar.Brand>*/}
            {/*        <Navbar.Item renderAs="a" href="#">*/}
            {/*            <img src="https://bulma.io/images/bulma-logo.png" alt="Bulma: a modern CSS framework based on Flexbox" width="112" height="28" />*/}
            {/*        </Navbar.Item>*/}
            {/*        <Navbar.Burger />*/}
            {/*    </Navbar.Brand>*/}
            {/*    <Navbar.Menu >*/}
            {/*        <Navbar.Container>*/}
            {/*            <Navbar.Item dropdown hoverable href="#">*/}
            {/*                <Navbar.Link arrowless={boolean('Arrowless', false)}>*/}
            {/*                    First*/}
            {/*                </Navbar.Link>*/}
            {/*                <Navbar.Dropdown>*/}
            {/*                    <Navbar.Item href="#">*/}
            {/*                        Subitem 1*/}
            {/*                    </Navbar.Item>*/}
            {/*                    <Navbar.Item href="#">*/}
            {/*                        Subitem 2*/}
            {/*                    </Navbar.Item>*/}
            {/*                </Navbar.Dropdown>*/}
            {/*            </Navbar.Item>*/}
            {/*            <Navbar.Item href="#">*/}
            {/*                Second*/}
            {/*            </Navbar.Item>*/}
            {/*        </Navbar.Container>*/}
            {/*        <Navbar.Container position="end">*/}
            {/*            <Navbar.Item href="#">*/}
            {/*                At the end*/}
            {/*            </Navbar.Item>*/}
            {/*        </Navbar.Container>*/}
            {/*    </Navbar.Menu>*/}
            {/*</Navbar>*/}

            {/*<nav>*/}
            {/*    <Link to="/">Home</Link> |{' '}*/}
            {/*    <Link to="data">Data</Link> |{' '}*/}
            {/*    <Link to="people">People</Link> |{' '}*/}
            {/*    <Link to="financial">Financial</Link> |{' '}*/}
            {/*    <Link to="process">Process</Link> |{' '}*/}
            {/*    <Link to="software">Software</Link>*/}
            {/*</nav>*/}
            {/*<Router>*/}
            {/*    <HomePage path="/" />*/}
            {/*    <DataPage path="/data"/>*/}
            {/*    <PeoplesPage path="/people" />*/}
            {/*    <FinancialPage path="/financial" />*/}
            {/*    <ProcessPage path="/process" />*/}
            {/*    <SoftwarePage path="/software" />*/}
            {/*</Router>*/}
        </div>
    );
}

export default App;
